import React, { Component } from 'react';
import professional_portrait from '../../../assets/images/professional_portrait.jpg';

class Code extends Component {
  render() {
    return (
      <div className='content-page container container-fluid'>
        <div className='row pt-sm-5'>
          <div className='col-sm pr-sm-5'>
            <div className='row'>
              <div className="col-sm">
                  <h1>Code</h1>
                  <p>I am a Software Engineer currently working as a DevOps Engineer professionally. I am working to expand into a more Full Stack Engineer role. My current focus of careeer development is currently picking up AWS Associate certifications. Aside from that I like to tinker with several side projects.</p>
              </div>
              <div className='col-sm'>
                <img src={professional_portrait} className="img img-fluid" />
            </div>
          </div>
          <div className='row'>
            <div className="col-sm pr-sm-5 code-row-2-margin-top">
              <h2>Current Prjects</h2>
              <p>I am currently working on the following prjoects:</p>
              <ul>
                <li><b>AWS Migrations:</b> as a way to reinforce my AWS knowledge I have been migrating applications I maintain over to AWS where it makes sense</li>
                <li><b>This Website:</b> I am trying to be better about updating this and making it interesting.</li>
                <li><b>Ansible Development Rig Provisioning:</b> I maintain a set of Ansible playbooks that allow me to easily provision a Linux or OSX machine.</li>
              </ul>
              <h2>Projects I Watch</h2>
              <ul>
                <li><b>VCV Rack</b> I have been watching the development of this largely open source Rack Synthesizer proejct. Check it out <a href="https://github.com/VCVRack/Rack">here</a></li>
              </ul>
            </div>
            <div className="col-sm pr-sm-5 code-row-2-margin-top">
              <h2>Contact</h2>
              <p>To check these out please feel free to visit my <a href="http://www.github.com/alecsherwood">Github</a> or for more information about my career check out my <a href="https://www.linkedin.com/in/alec-sherwood-73a01469/">Linkedin</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default Code;
