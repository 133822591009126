import React, { Component } from 'react';
import atx_sign from '../../../assets/images/atx.jpg';
import fountain from '../../../assets/images/fountain_atx.jpg';
import skyline from '../../../assets/images/skyline_atx.jpg';

class Travel extends Component {
  render() {
    return (
      <div className='content-page container container-fluid'>
        <div className='row pt-sm-5'>
          <div className='col-sm pr-sm-5'>
            <h1>Travel</h1>
            <p>I have been traveling the world since I was very small. Starting off with a family trip to London I have gone on to visit Norway, France and most recently Germany!
            I have brought back lots of great memories from each trip.</p>
            <h3>My Latest Trip: Austin, TX</h3>
            <p>This past October I was fortunate enough to travel to Austin Texas for Austin City Limits. Coming from Boston I instantly got a different vibe from Austin.
              One of the first things I realized is that Austin unlike Boston is not very walkable. Despite this that is exactly what I did. I took   a fairly lengthy loop around the downtown area and down by the festival grounds.
              In doing so I waas able to experience a lot of the art and architecture around the city. Additionally this provided an excellent opportunity to dive into the local food scene. Lot's of BBQ.
            </p>
            <p>In addition to exploring the city I also went to the Austin City Limits music festival. There were a lot of great bands showcased on the day I went. I was fortunate to see Tame Impala, K.flay, The Raconteurs and many others. </p>
            <h3>Up Next!</h3>
            <p>My next travel goals and plans include:</p>
            <ul>
              <li>Japan</li>
              <li>Iceland</li>
              <li>New York City</li>
            </ul>
          </div>
          <div className='col-sm'>
            <img src={atx_sign} className="img img-fluid" />
            <img src={skyline} className="img img-fluid" />
            <img src={fountain} className="img img-fluid" />
          </div>
        </div>
      </div>
    );
  }
}

export default Travel;
