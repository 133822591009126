import React, { Component } from 'react';
import Bass from "../../../assets/images/bass_outside.jpg";
import './music.css'

class Music extends Component {
  render() {
    return (
      <div className='content-page container container-fluid'>
        <div className='row pt-sm-5'>
          <div className='col-sm pr-sm-5'>
            <div className='row'>
              <div className="col-sm">
                <div className="music-container">
                  <h1>Music</h1>
                  <p>I have been playing music in some capacity since I was 5 and more seriously at 10. I have dabbled in everything from drums to vocals but primarily play guitar and bass. My main focus at the moment is on bass and learning to be a bass player and not just a guitar player who can play bass.</p>
                </div>
                <div className="bands-container">
                  <h2>Bands</h2>
                  <ul className="bands-list">
                    <li><b>Company Z:</b> This was the name I went under for all of my music creation in High School.</li>
                    <li><b>Electric Tin Openers:</b> This is my latest band. This band was spun up to do the <a href="https://www.rpmchallenge.com/" target="_blank" rel="noreferrer">RPM Challenge</a>. A challenge to creat a record in the month
                    of February. Updates on this as well as the link to the site will be provided as available.</li>
                  </ul>
                </div>
                <div className="goals-container">
                  <h3>Music Goals For This Year</h3>
                  <ul>
                    <li><b>Fully release an EP</b> - I have put up lots of music on soundclod but have yet to release anything official and polished to the world.</li>
                    <li><b>Perform live</b> - I am in a cover band in addition to the solo writing that I do. I would like us to get to a point where we could play live semi-regularly.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm'>
            <img src={Bass} className="img img-fluid" />
          </div>
        </div>
      </div>
    );
  }
}

export default Music;
