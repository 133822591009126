import React, { Component } from 'react';

import {
  Link
} from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <div className="footer container-fluid">
      <div className='container'>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='row'>
                <div className='col-sm'>
                  <h3> About This Site</h3>
                  <p>This site was is a single page React app created in part as a portfolio piece and in part as a learning exercise.</p>
                </div>
              </div>
            </div>
            <div className='col-sm-4'>
              <h3>Contact</h3>
              <ul>
                <li><a href="https://www.github.com/alecsherwood">Github</a></li>
                <li><a href="https://www.linkedin.com/in/alec-sherwood">Linkedin</a></li>
                <li><a href='https://www.instagram.com/alecasaurus/?hl=en'>Instagram</a></li>
              </ul>
            </div>
            <div className='col-sm-4'>
              <h3>Site Map</h3>
              <ul className="site-map">
                <li className="">
                  <Link to="/" className="">Home</Link> 
                </li>
                <li className="">
                  <Link to='/code' className="">Code</Link> 
                </li>
                <li className="">
                  <Link to='/music' className="">Music</Link>
                </li>
                <li className="">
                  <Link to='/travel' className="">Travel</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
