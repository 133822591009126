import React, { Component } from 'react';

import {
  Link
} from 'react-router-dom';

class Header extends Component {
  render() {
    return (
      <header>
        <nav className="navbar mr-auto navbar-dark bg-dark justify-content-between">
          <div className="container">
            <Link to='/' className='logo-text'>Alec Sherwood</Link>
            <ul className='nav ml-auto'>
              <li className="nav-item">
                <Link to='/code' className="nav-link">Code</Link>
              </li>
              <li className="nav-item">
                <Link to='/music' className="nav-link">Music</Link>
              </li>
              <li className="nav-item">
                <Link to='/travel' className="nav-link">Travel</Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
